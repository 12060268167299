// import * as React from 'react'
import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { SliceZone } from '@prismicio/react'
import moment from 'moment';

import { Layout } from '../components/Layout'
import {Events} from '../components/Events'
import { components } from '../slices'

const EventsTemplate = ({ data }) => {
  console.log('EventsTemplate data: ', data);
  
  const [events,setEvents] = useState([])
  
  useEffect( ()=> {
    const docs = data.allPrismicSifiEvent || []
    // src/components/Events.js
    const now = moment().utc()
    const _filtered = docs.nodes.map(item => {
      // console.log('item: ', item)
      item.data.startTime = moment(item.data.start_time).unix();
      const finishUtc = moment(item.data.finish_time).tz("Europe/Berlin")
      const isOver = now.isAfter(finishUtc)
      item.data.isOver = isOver;
      item.url = `${window.location.origin}/events/${item.uid}`;
      return item;
    }).sort((a,b) => {
      return b.data.startTime - a.data.startTime;
    });
    // const over_1 = _filtered.filter(item => {
    //   return item.data.isOver;
    // });
    // const over_2 = _filtered.filter(item => {
    //   return !item.data.isOver;
    // });
    // const filtered = [...over_2, ...over_1];
    // setEvents(filtered)
    setEvents(_filtered)
  }, [data.allPrismicSifiEvent])
  
  if (!data) return null

  const homepage = data.prismicSifiEvents || {}
  const seo = {
    title: homepage.data.seo_title,
    description: homepage.data.seo_description,
    keywords: homepage.data.seo_keywords,
  }
  console.log('seo: ', seo)

  const { lang, type, url } = homepage || {}
  const alternateLanguages = homepage.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }
  const topMenu = data.prismicTopMenu || {}
  const footer = data.prismicFooter || {}

  const docs = data.allPrismicSifiEvent
  docs.events = events
  console.log('events: ', events)

  // return (
  //   <Layout topMenu={topMenu.data} activeDocMeta={activeDoc}>
  //     <SliceZone slices={homepage.data.body} components={components} />
  //   </Layout>
  // )
  // return (
  //   <Layout>
  //     {/* <div className="home-header container" data-wio-id={home.id}>
  //       <div className="blog-avatar" style={avatar} />
  //       <h1>{home.headline.text}</h1>
  //       <p className="blog-description">{home.description.text}</p>
  //     </div> */}
  //     <Events docs={docs} />
  //   </Layout>
  // )
  return (
    <Layout topMenu={topMenu.data} footer={footer.data} activeDocMeta={activeDoc} seo={seo}>
      <div className="bg-white">
        <div className="container py-80px">
          <h1 className="h2 text-center text-primary">{homepage.data.title.text}</h1>
          <Events docs={docs} />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query myEventsQuery($lang: String) {
    prismicTopMenu {
      ...TopMenuFragment
    }
    prismicFooter {
      ...FooterFragment
    }
    prismicSifiEvents(lang: { eq: $lang }) {
      _previewable
      data {
        seo_title
        seo_description
        seo_keywords
        title_section_image {
          url
        }
        title {
          text
        }
        description {
          richText
        }
      }
      alternate_languages {
        uid
        type
        lang
      }
      lang
      url
      type
    }

    allPrismicSifiEvent(
      sort: {fields: data___start_time, order: DESC}
      filter: {data: {restaraunt_link: {id: {eq: "YiX8wREAACgAH1oD"}}}}
      limit: 15
      skip: 0
      #filter: {
        #data:{
          #finish_time:{gte: $now}
        #}
      #}
    ) {
      nodes {
        _previewable
        uid
        url
        data {
          title {
            text
          }
          start_time
          finish_time
          body {
            ... on PrismicSifiEventDataBodyEventInfo {
              id
              slice_label
              slice_type
              items {
                ... on PrismicSifiEventDataBodyEventInfoItem {
                  image {
                    url
                  }
                }
              }
              primary {
                ... on PrismicSifiEventDataBodyEventInfoPrimary {
                  description {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default withPrismicPreview(EventsTemplate)
